import React, {useEffect} from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from React Router
import { BrowserView, MobileView, isMobile } from 'react-device-detect';

const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const cookies = new Cookies();
    cookies.remove('sessionToken');
    cookies.remove('sessionData');
    cookies.remove('sessionExist');
    window.isLogin = false;
  }, [])
  const handleRefresh = () => {
    window.location.href="/";
  };
  return (
    <>
      <div className="col-md-6">
        <div className="card card-success">
          <div className="card-header">
            <h3 className="card-title">Logout</h3>
          </div>
          <div className="card-body">
            <p>You have been logged out.</p>
            <button style={{backgroundColor: '#0296D2', borderRadius: '8px', fontFamily:'Blender Pro', height: isMobile? '5vh':'2.8vw', marginTop:'0vw'}} className="btn btn-primary " onClick={handleRefresh}>
              Back to Home
            </button>
          </div>
        </div> 
      </div>
    </>
  )
  };
  
  export default Logout;