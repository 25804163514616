import React, { useState, useEffect } from 'react';
import HeaderPage from '../headerPage/HeaderPage';
import {useNavigate, useParams} from "react-router-dom"
import Tabbing from './Tabbing';
import FilterSearch from '../filter/FilterSearch';
import TicketTableParent from './TicketTableParent';
import useOrientation from '../../general/useOrientation';
import { isMobile } from 'react-device-detect';

function TicketList({changeNavState}) {
  const navigate = useNavigate();
  const { byTo,id,name } = useParams();
  const [imagePopup, setImagePopup] = useState("");
  const [filterData, setFilterData] = useState("");
  const [isExpanded, setIsExpanded] = useState(0);
  const [linkData, setLinkData] = useState(byTo == "to" ? 'ticketListToMe':'ticketListByMe');
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [selectedTab, setSelectedTab] = useState(byTo == "to" ? 'Tickets To Me':'Tickets By Me');
  const buttonText = "Create Ticket";
  const orientation = useOrientation();

  useEffect(() => {
    changeNavState('Ticket');
  }, [])

  const buttonAction = (() => {
    navigate('/createTicket');
  })
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRefresh(!refresh);
    }, 60000); // 60 seconds in milliseconds
  
    // Clear the initial timeout to prevent the initial refresh
    const initialTimeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, 60000);
  
    // Cleanup function to clear the interval and initial timeout when the component unmounts
    return () => {
      clearInterval(intervalId);
      clearTimeout(initialTimeoutId);
    };
  }, []); // Empty dependency array for the initial setup
  
  

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    if (tab == "Tickets To Me"){
      setLinkData('ticketListToMe');
    } else if (tab == "Tickets By Me"){
      setLinkData('ticketListByMe');
    } else if (tab == "Drafts"){
      setLinkData('ticketListByMeDraft');
    } else if (tab == "Ticket Files"){
      setLinkData('fileList');
    }
  };

  const handleRefreshClick = () => {
    // just change the value to tell components to refresh
    setRefresh(!refresh); 
  };
  
  const updateFilterData = (val) => {
    console.log("update filter data value ", val)
    setFilterData(val); 
  };

  const handleImageClick = (val) => {
    if (isExpanded == 0){
      setIsExpanded(val);
    } else {
      setIsExpanded(0);
      setImagePopup("");
    }
  };

  if (orientation === 'landscape')
  return (
  <>
     <div className={isExpanded == 0 ? '' : 'popup-overlay'}  onClick={() => handleImageClick(0)}>

     </div>
     {imagePopup && (
       <img
       src={process.env.REACT_APP_IMG_DESTINATION + imagePopup}
       alt="Image Popup"
       className="expanded"
       style={{ height: "3vw", marginTop: '2.2vw', position: 'absolute' }}
     />
     )}
     <HeaderPage 
        title1={"Ticket"}
        link1={"/ticketList"}
        title2={"Ticket List"}
        buttonText={buttonText}
        buttonAction={buttonAction}
        loadingState={isLoading}
    />

    <Tabbing 
      selectedTab={selectedTab}
      handleTabClick={handleTabClick}
    />
     <div style={{position:'relative'}}>
      <div style={{zIndex:2 ,position: 'relative', width:'78%'}}>
        <FilterSearch init={id} name={name} data={filterData} setData={updateFilterData} handleRefreshClick={handleRefreshClick} tiketOrDoc="tiket" bymeToMe={linkData}/>
      </div>
      <div style={{zIndex:1, position: 'relative', marginTop:'-7vw', marginLeft:'-1.2vw'}}>
        <TicketTableParent filterData={filterData} link={linkData} refresh={refresh} setLoading={setIsLoading}  imagePopup={imagePopup} setImagePopup={setImagePopup} isExpanded={isExpanded} setIsExpanded={setIsExpanded}/>
      </div>
     </div>
  </>
  )

  if (orientation === 'portrait')
  return (
  <>
     <div className={isExpanded == 0 ? '' : 'popup-overlay'}  onClick={() => handleImageClick(0)}>

     </div>
     {imagePopup && (
       <img
       src={process.env.REACT_APP_IMG_DESTINATION + imagePopup}
       alt="Image Popup"
       className="expanded"
       style={{ height: "3vw", marginTop: '2.2vw', position: 'absolute' }}
     />
     )}
     <HeaderPage 
        title1={"Ticket"}
        link1={"/ticketList"}
        title2={"Ticket List"}
        buttonText={buttonText}
        buttonAction={buttonAction}
        loadingState={isLoading}
    />

    <Tabbing 
      selectedTab={selectedTab}
      handleTabClick={handleTabClick}
    />
     <div style={{position:'relative'}}>
      <div style={{zIndex:2 ,position: 'relative', width:'78%'}}>
        <FilterSearch init={id} name={name} data={filterData} setData={updateFilterData} handleRefreshClick={handleRefreshClick} tiketOrDoc="tiket" bymeToMe={linkData}/>
      </div>
      <div style={{zIndex:1, position: 'relative', marginTop: '-12vh', marginLeft:'-1.2vw'}}>
        <TicketTableParent filterData={filterData} link={linkData} refresh={refresh} setLoading={setIsLoading}  imagePopup={imagePopup} setImagePopup={setImagePopup} isExpanded={isExpanded} setIsExpanded={setIsExpanded}/>
      </div>
     </div>
  </>
  )
}

export default TicketList