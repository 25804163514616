import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import useOrientation from '../../general/useOrientation';
const cookies = new Cookies();
function Tabbing({selectedTab, handleTabClick}) {
  var sessionData = cookies.get('sessionData', { path: '/' })
  const orientation = useOrientation();
  if(sessionData.int_ext == "1" && orientation === 'landscape'){
    return (
      <div className='row' style={{ position:'relative', zIndex:'2',borderBottom: '1px solid #E8E9EB', width: '98%', marginTop: '3vw'}}>
        <div className={`tabbing ${selectedTab === 'Tickets By Me' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Tickets By Me')}>
          Tickets By Me
        </div>
        <div className={`tabbing ${selectedTab === 'Tickets To Me' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Tickets To Me')}>
          Tickets To Me
        </div>
        <div className={`tabbing ${selectedTab === 'Drafts' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Drafts')}>
          Drafts
        </div>
        <div className={`tabbing ${selectedTab === 'Ticket Files' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Ticket Files')}>
          Ticket Files
        </div>
      </div>
    )
  } else if(sessionData.int_ext == "1" && orientation === 'portrait'){
    return (
      <div className='row' style={{ position:'relative', zIndex:'2',borderBottom: '1px solid #E8E9EB', width: '98%', marginTop: '1.5vh',  marginBottom: '2vh', marginLeft:'2vw'}}>
        <div className={`tabbingMobile ${selectedTab === 'Tickets By Me' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Tickets By Me')}>
          Tickets By Me
        </div>
        <div className={`tabbingMobile ${selectedTab === 'Tickets To Me' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Tickets To Me')}>
          Tickets To Me
        </div>
        <div className={`tabbingMobile ${selectedTab === 'Drafts' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Drafts')}>
          Drafts
        </div>
        <div className={`tabbingMobile ${selectedTab === 'Ticket Files' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Ticket Files')}>
          Ticket Files
        </div>
      </div>
    )
  } else if (orientation === 'landscape') {
    return (
      <div className='row' style={{ position:'relative', zIndex:'2',borderBottom: '1px solid #E8E9EB', width: '98%', marginTop: '3vw'}}>
        <div className={`tabbing ${selectedTab === 'Tickets By Me' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Tickets By Me')}>
          Tickets By Me
        </div>
        <div className={`tabbing ${selectedTab === 'Drafts' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Drafts')}>
          Drafts
        </div>
        <div className={`tabbing ${selectedTab === 'Ticket Files' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Ticket Files')}>
          Ticket Files
        </div>
      </div>
    )
  } else if (orientation === 'portrait'){
    return (
      <div className='row' style={{ position:'relative', zIndex:'2',borderBottom: '1px solid #E8E9EB', width: '98%', marginTop: '1.5vh',  marginBottom: '2vh', marginLeft:'2vw'}}>
        <div className={`tabbingMobile ${selectedTab === 'Tickets By Me' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Tickets By Me')}>
          Tickets By Me
        </div>
        <div className={`tabbingMobile ${selectedTab === 'Drafts' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Drafts')}>
          Drafts
        </div>
        <div className={`tabbingMobile ${selectedTab === 'Ticket Files' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Ticket Files')}>
          Ticket Files
        </div>
      </div>
    )
  }
}

export default Tabbing;
